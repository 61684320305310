var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alert-box-container", class: _vm.typeClass },
    [
      _c(
        "div",
        { staticClass: "alert-box-header", class: _vm.headerBorderClass },
        [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-row align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass:
                      "alert-box-header-icon mr-4 material-icons-outlined",
                  },
                  [_vm._v(_vm._s(_vm.typeIcon))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-row" },
              [
                _vm._t("title", function () {
                  return [_vm._v(" " + _vm._s(_vm.title) + " ")]
                }),
                _vm._t("text", function () {
                  return [_vm._v(" " + _vm._s(_vm.text) + " ")]
                }),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "d-flex flex-row align-center" }, [
            _vm.hasHeaderLink
              ? _c(
                  "div",
                  { staticClass: "alert-box-header-link" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-ag-link",
                        attrs: {
                          id: _vm.idHeaderLink,
                          type: "link",
                          ripple: false,
                        },
                        on: { click: _vm.onClickHeaderLink },
                      },
                      [_vm._v(" " + _vm._s(_vm.headerLinkText) + " ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.progress
              ? _c(
                  "div",
                  { staticClass: "alert-box-progress" },
                  [
                    _c("v-progress-circular", {
                      attrs: {
                        indeterminate: "",
                        color: "#442D96",
                        size: "16",
                        width: "2",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.hasExtraContainer
              ? _c(
                  "div",
                  {
                    staticClass:
                      "expand-alert-box-action d-flex flex-row align-center",
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-ag-link",
                        attrs: { id: _vm.idExpandButton, ripple: false },
                        on: { click: _vm.toggleExpand },
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.expandText) + " "),
                        _c("v-icon", [_vm._v(_vm._s(_vm.expandIcon))]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.hasCloseButton
              ? _c(
                  "div",
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "alert-box-close-button",
                        on: { click: _vm.onClickClose },
                      },
                      [_vm._v("close")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm.hasExtraContainer
        ? _c(
            "transition",
            { attrs: { name: "slide-vertical", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isExpanded,
                      expression: "isExpanded",
                    },
                  ],
                  staticClass: "alert-box-extra-container",
                },
                [_vm._t("container-extra")],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }