<template>
    <div class="alert-box-container" :class="typeClass">
        <div class="alert-box-header" :class="headerBorderClass">
            <div class="d-flex">
                <div class="d-flex flex-row align-center">
                    <v-icon class="alert-box-header-icon mr-4 material-icons-outlined">{{typeIcon}}</v-icon>
                </div>
                <div class="d-flex flex-row">
                    <slot name="title">
                        {{ title }}
                    </slot>
                    <slot name="text">
                        {{ text }}
                    </slot>
                </div>
            </div>
            <div class="d-flex flex-row align-center">
                <div v-if="hasHeaderLink" class="alert-box-header-link">
                    <v-btn 
                        :id="idHeaderLink"
                        class="btn-ag-link" 
                        type="link" 
                        :ripple="false" 
                        @click="onClickHeaderLink"
                    >
                        {{ headerLinkText }}
                    </v-btn>
                </div>
                <div class="alert-box-progress" v-if="progress">
                    <v-progress-circular
                        indeterminate
                        color="#442D96"
                        size="16"
                        width="2"
                    ></v-progress-circular>
                </div>
                <div v-if="hasExtraContainer" class="expand-alert-box-action d-flex flex-row align-center">
                    <v-btn 
                        :id="idExpandButton"
                        class="btn-ag-link"
                        :ripple="false"
                        @click="toggleExpand"
                    >
                        {{ expandText }} 
                        <v-icon>{{ expandIcon }}</v-icon>
                    </v-btn>
                </div>
                <div v-if="hasCloseButton">
                    <v-icon class="alert-box-close-button" @click="onClickClose">close</v-icon>
                </div>
            </div>
        </div>
        
        <transition name="slide-vertical" mode="out-in" v-if="hasExtraContainer">
            <div class="alert-box-extra-container" v-show="isExpanded">
                <slot name="container-extra">

                </slot>
            </div>
        </transition>
    </div>
</template>

<script>

import { uniqueId } from 'lodash';

export default {
    name: "AlertBox",

    props: {
        title: {
            type: String,
            required: false
        },
        text: {
            type: String,
            required: false
        },
        type: {
            type: String,
            default: ''
        },
        hasHeaderLink: {
            type: Boolean,
            default: false
        },
        headerLinkText: {
            type: String,
            default: "Click here"
        },
        progress: {
            type: Boolean,
            default: false
        },
        hasExtraContainer: {
            type: Boolean,
            default: false
        },
        hasCloseButton: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isExpanded: false,
        }
    },
    computed: {
        idExpandButton () {
            return uniqueId('expandAlertBox_');
        },
        idHeaderLink () {
            return uniqueId('headerLinkAlertBox_');
        },
        typeClass() {
            let typeClass = '';
            switch (this.type) {
                case 'info':
                    typeClass = 'alert-box-info';
                    break;
                case 'success':
                    typeClass = 'alert-box-success';
                    break;
                case 'warning':
                    typeClass = 'alert-box-warning';
                    break;
                case 'error':
                    typeClass = 'alert-box-error';
                    break;
                default:
                    typeClass = 'alert-box-default';
            }
            return typeClass;
        },
        typeIcon() {
            let typeIcon = '';
            switch (this.type) {
                case 'info':
                    typeIcon = 'info';
                    break;
                case 'success':
                    typeIcon = 'check_circle';
                    break;
                case 'warning':
                    typeIcon = 'warning_amber';
                    break;
                case 'error':
                    typeIcon = 'cancel';
                    break;
                default:
                    typeIcon = 'description';
            }
            return typeIcon;
        },
        expandIcon() {
            return this.isExpanded ? 'expand_less' : 'expand_more';
         },
        expandText() {
            return this.isExpanded ? 'Ocultar detalhes' : 'Exibir detalhes';
        },
        headerBorderClass() {
            return this.isExpanded ? 'alert-box-border-expanded' : '';
        }
    },
    methods: {
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
        },
        onClickHeaderLink(e) {
            this.$emit('click:headerLink', e);
        },
        onClickClose() {
            this.$emit('click:close', e);
        }
    }
}

</script>

<style scoped lang="scss">
@import './src/design/variables.scss';

@mixin typeStyles($borderColor, $headerTextColor, $headerBackgroundColor) {
    .alert-box-header, .alert-box-extra-container {
        border-color: $borderColor;
    }
    .alert-box-header {
        color: $headerTextColor;
        background-color: $headerBackgroundColor;
        .alert-box-header-icon {
            color: $headerTextColor;
        }
    }
}

.alert-box-container {
    
    .alert-box-header, .alert-box-extra-container {
        padding: $spacing-inset-05;
    }

    .alert-box-header {
        border: $border-width-hairline $border-default;
        border-radius: $border-radius-sm;
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .alert-box-extra-container {
        border: $border-width-hairline $border-default;
        border-radius: $border-radius-sm;
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
        background-color: $neutral-color-low-light;
    }
    .alert-box-border-expanded {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .alert-box-close-button {
        cursor: pointer;
        color: $brand-color-primary-medium;
        font-size: $font-size-sm;
    }
    .alert-box-progress, .alert-box-header-link, .alert-box-close-button, .expand-alert-box-action {
        margin-left: $spacing-05;
    }
    .expand-alert-box-action {
        i {
            margin-right: 0;
        }
    }
    &.alert-box-default {
        @include typeStyles($brand-color-primary-medium, $brand-color-primary-medium, $neutral-color-low-light);
    }
    &.alert-box-info {
        @include typeStyles($feedback-color-information-medium, $feedback-color-information-dark, $feedback-color-information-light);
    }
    &.alert-box-success {
        @include typeStyles($feedback-color-success-medium, $feedback-color-success-dark, $feedback-color-success-light);
    }
    &.alert-box-warning {
        @include typeStyles($feedback-color-attention-medium, $feedback-color-attention-dark, $feedback-color-attention-light);
    }
    &.alert-box-error {
        @include typeStyles($feedback-color-error-medium, $feedback-color-error-dark, $feedback-color-error-light);
    }
}



</style>